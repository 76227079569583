import React from "react";
import "./promo.scss";
import penawaran from "../../../media/promo kbp.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6283110558510&text=Halo%20Dimas,%20Saya%20bisa%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20https://kbp-kotabaruparahyangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyload: true,
  };
  return (
    <div className="judulpromo">
      <div id="promo" className="judul">
        <h1>OUR PROMO</h1>
      </div>
      <div id="promo" className=" containerPenawaran">
        <div className="containercontent">
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "rgb(72, 72, 72)"}}>
                &nbsp;&nbsp;Soft DP
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "rgb(72, 72, 72)"}}>
                &nbsp;&nbsp;Free Canopy
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "rgb(72, 72, 72)"}}>
                &nbsp;&nbsp;Free Smart Door Lock
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "rgb(72, 72, 72)"}}>
                &nbsp;&nbsp;Free Smarthome System
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "rgb(72, 72, 72)"}}>
                &nbsp;&nbsp;Free Logam Mulia up to 17gr
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "rgb(72, 72, 72)"}}>
                &nbsp;&nbsp;Discount Biaya Provisi 45%
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={penawaran} />
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
